





























import { Component, Vue } from "vue-property-decorator";
import { LocalHostToPartnerMapper } from "@/lib/utility/local-host-to-partner-mapper";
import { ConfigModule } from "@/store/modules/config";
import { AppColorSchema } from "@/lib/utility/AppColorSchema";

@Component({
  components: {}
})
export default class V extends Vue {
  async mounted() {
    new AppColorSchema(this.$vuetify, ConfigModule.damageReportColorSchema);
    try {
      await LocalHostToPartnerMapper.tryGetPartnerByHost(window.location.hostname);
    } catch (error) {
      this.$log.error(error);
      this.$toast("Fehler beim Laden der Anwendung.");
      this.$router.push({ name: "PartnerContactError" });
    }
  }
}
