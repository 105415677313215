import { CookieJar } from "./../store/modules/cookie.store";
import VueGtag from "vue-gtag";
import VueCookies from "vue-cookies";
import VueGtm from "@gtm-support/vue2-gtm";
import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLogger from "vuejs-logger";
import vuetify from "../plugins/VuetifyPlugin";
import "../registerServiceWorker";
import router from "../router/form.router";
import store from "../store/VuexPlugin";
import Form from "./Form.vue";
import { UIHelper } from "../lib/UIHelper";
import { ConfigModule } from "@/store/modules/config";
import { abilitiesPlugin, Can } from "@casl/vue/";
import { UserModule } from "@/store/modules/me-user.store";
import i18n from "../plugins/I18nPlugin";
import VueAppInsights from "../plugins/AppInsightsPlugin";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

const toastOptions = {
  position: "bottom-right"
};

Vue.use(Toast, toastOptions);

Vue.use(VueLogger as any, options);

Vue.use(UIHelper);

Vue.use(abilitiesPlugin, UserModule.abilities);

Vue.component("Can", Can);

// If a user access the app via the website, the cookie might be set based on consent
Vue.use(VueCookies);

// Analytics cookie starts with _ga
const cookie = Vue.$cookies.get("_ga");

// If the cookie is set we can enable google analytics.
const bootstrap = (Boolean(cookie) || CookieJar.isConsent) ?? false;
Vue.$log.warn(`bootstrap ${bootstrap}`);

// In a later version of VueGtag we might be able to access the 'consent' config - currently its not in definitely typed Gtag type. https://github.com/DefinitelyTyped/DefinitelyTyped/blob/6e8addd5edd11b8ec9a7253cb02fd83b6495fc1d/types/gtag.js/index.d.ts#L19
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-XNWT0X6B1L"
    },
    bootstrap
  },
  router
);

// https://www.npmjs.com/package/@gtm-support/vue2-gtm
Vue.use(VueGtm, {
  id: "GTM-KP29C5Z", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: bootstrap, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: !isProduction, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: true // Whether or not call trackView in Vue.nextTick
});

Vue.config.productionTip = false;

vuetify.framework.theme.dark = ConfigModule.darkMode;

const url = ConfigModule.mrfiktivBaseUrl + "/config";
Vue.use(VueAppInsights, {
  baseName: "form",
  url: url,
  id: ConfigModule.appInsightsKey,
  cookie: false, // disable by default
  router
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(Form)
}).$mount("#form");
