var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "value": _vm.isCookieDialogDisplayed,
      "width": "500"
    },
    on: {
      "click:outside": _vm.deny
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")]), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.text))
    }
  }), _c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "flat": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "card-panel"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.CookieConsentDialog.trackingTitle")) + " ")]), _c('v-expansion-panel-content', {
    staticClass: "card-panel"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.CookieConsentDialog.trackingNote")) + " ")])], 1)], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.dialog.CookieConsentDialog.privacyNote")) + " "), _c('a', {
    attrs: {
      "href": _vm.privacyPolicyLink
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.CookieConsentDialog.privacyPolicy")))])]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "data-test-consent-button-deny": "",
      "color": "grey lighten-1",
      "text": ""
    },
    on: {
      "click": _vm.deny
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.CookieConsentDialog.deny")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "data-test-consent-button-ack": "",
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.acknowledge
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.CookieConsentDialog.consent")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }