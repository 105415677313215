import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";

@Module({
  dynamic: true,
  namespaced: true,
  name: "cookieStore",
  store
})
export class CookieStore extends VuexModule {
  /**
   * Local Store Strings
   */
  DISPLAY_DIALOG = "cookieConsentDialogDisplayed";

  /**
   * Local Store Strings
   */
  CONSENT = "cookieConsent";

  /**
   * Are cookies allowed?
   */
  _isConsent: boolean = JSON.parse(localStorage.getItem(this.CONSENT) || "false") as boolean;

  /**
   * Is the cookie consent dialog displayed?
   */
  _isConsentDialogDisplayed: boolean = JSON.parse(localStorage.getItem(this.DISPLAY_DIALOG) || "true") as boolean;

  get isConsent(): boolean {
    return this._isConsent;
  }

  @Mutation
  _mutateConsent(value: boolean) {
    this._isConsent = value;
  }

  @Action
  setConsent(value: boolean) {
    localStorage.setItem(this.CONSENT, String(value));
    this.context.commit("_mutateConsent", value);
  }

  get isConsentDialogDisplayed(): boolean {
    return this._isConsentDialogDisplayed;
  }

  @Mutation
  _mutateIsConsentDialogDisplayed(value: boolean) {
    this._isConsentDialogDisplayed = value;
  }

  @Action
  setConsentDialogDisplayed(value: boolean) {
    localStorage.setItem(this.DISPLAY_DIALOG, String(value));
    this.context.commit("_mutateIsConsentDialogDisplayed", value);
  }
}

export const CookieJar = getModule(CookieStore);
