var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('Loader') : _vm._e(), !_vm.loading ? _c('cookie-consent-dialog', {
    on: {
      "consent": _vm.cookieConsent
    }
  }) : _vm._e(), !_vm.loading ? _c('PartnerContact') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }