









































import { Component, Vue, Prop } from "vue-property-decorator";

import { CookieJar } from "@/store/modules/cookie.store";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {}
})
export default class CookieConsentDialog extends Vue {
  /**
   * The event emitted by the dialog on consent action.
   */
  public EMIT_EVENT = "consent";

  /**
   * Optional: Title of the dialog
   **/
  @Prop({ required: false, default: "components.dialog.CookieConsentDialog.defaultTitle" })
  title!: string;

  /**
   * Optional: Text of the dialog
   **/
  @Prop({ required: false, default: "components.dialog.CookieConsentDialog.defaultText" })
  text!: string;

  /**
   * Displays the dialog.
   */
  get isCookieDialogDisplayed() {
    return CookieJar.isConsentDialogDisplayed;
  }

  /**
   * Get link to privacy policy
   */
  get privacyPolicyLink() {
    return ConfigModule.privacyPolicyLink;
  }

  /**
   * Consent and closes the dialog
   */
  acknowledge() {
    CookieJar.setConsentDialogDisplayed(false);
    CookieJar.setConsent(true);

    this.$emit(this.EMIT_EVENT, true);
  }

  /**
   * Denies the consent and closes the dialog
   */
  deny() {
    CookieJar.setConsentDialogDisplayed(false);
    CookieJar.setConsent(false);
    this.$emit(this.EMIT_EVENT, false);
  }
}
