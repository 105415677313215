








import Loader from "@/components/utility/Loader.vue";
import CookieConsentDialog from "@/components/dialog/CookieConsentDialog.vue";

import { PartnerModule } from "@/store/modules/partner";
import PartnerContact from "@/views/PartnerContact.vue";
import { Component, Vue } from "vue-property-decorator";
import { bootstrap } from "vue-gtag";

@Component({
  components: {
    PartnerContact,
    CookieConsentDialog,
    Loader
  }
})
export default class Contact extends Vue {
  get loading() {
    return PartnerModule.loading;
  }

  async mounted() {
    // Handle special case if partner by route e.g. `/partner/mmmint.ai/report`.
    if (this.$route.params.partnerName) {
      try {
        await PartnerModule.getPartnerByName(this.$route.params.partnerName);
      } catch (error) {
        this.$log.error(error);
        this.$toast("Fehler beim Laden der Anwendung.");
        this.$router.push({ name: "PartnerContactError" });
      }
    }
  }

  /**
   *
   */
  async cookieConsent(enable: boolean) {
    if (enable) {
      this.$gtm.enable(enable);
      await bootstrap();

      this.$gtm.trackView("Contact", "/form/");
    }
  }
}
